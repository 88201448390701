.container {
  margin-top: 6%;
}

.upload-file-container {
  /* border: 1px solid gray; */
  min-height: 350px;
  margin: 0 20px;
  height: 75vh;
  padding: 10px;
  /* background: #b1aaaa2e; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-details-container {
  border: 1px solid gray;
  margin: 0 20px;
  height: auto;
  min-height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 50px;
}

.file-upload .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}


.file-upload p{
font-size: 16px;
}

.pdf-form {
  width: 75%;
  display: flex;
  padding: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pdf-label {
  font-weight: bold;
  font-size: 22px;
}

.pdf-template-name {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  margin-top: 10px;
}

.custom-file-new {
  height: auto !important;
  text-align: center;
}

.pdf-view {
  width: 60%;
  height: 100%;
  background: #f4f4f4a8;
  /* border: 1px solid black; */
}

.upload-btn-template{
  z-index: 999;
}

.spinner-template {
    width: 27px;
    height: 27px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}