.side-pdf-list {
    overflow-y: scroll;
    overflow-x: hidden;
}


.side-pdf-list-item {
    border: 2px solid grey;
    margin: 16px 8px;
}

.side-pdf-list .react-pdf__Page__canvas{
    width: 100%!important;
    height: 138px!important;
}

.text-content .react-pdf__Page__textContent {
    width: 0%!important;
}

.page-no {
    display: flex;
    justify-content: center;
}
