.pdf-container {
  /* display: -webkit-box;
  display: -ms-flexbox; */
  /* display: flex;
  height: 100vh; */
  overflow-x: hidden;
  /* justify-content: space-between; */
  /* Hide scrollbars */
}

.pdfOptions {
  /* height: 100vh; */
  display: flex;
}

.set-up-pdf .react-pdf__Page__canvas {
  /* height: 98.5vh !important; */
}

.set-up-pdf .react-pdf__Page__textContent {
  width: 0% !important;
}

.canvas-size {
  overflow: initial !important;
}
.custom-width-pdf{width: 770px !important;}
.fieldscss ul{ list-style: none; padding-left: 0; background: #f4f4f4ed;}
.fieldscss h6{ font-size: 22px; margin: 20px 15px;}
.fieldscss ul li{padding: 8px 0;
    border-bottom: 1px solid #ccc;}


.side-pdf-list h6{ font-size: 22px; margin: 20px 15px;}


