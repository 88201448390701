.side-pdf-list {
    overflow-y: scroll;
    overflow-x: hidden;
}

.desc-container {
    .row {
        margin: 0%;
        padding: 4px 10px;
    }
}
