.side-pdf-list {
  overflow-y: scroll;
  overflow-x: hidden;
}

.desc-container .row {
  margin: 0%;
  padding: 4px 10px;
}

.select-role {
  width: 100%;
    font-weight: bold;
}

.select-required {
  width: 100%;
  padding: 6px 0;
}


.spinner-pdf-submit {
    width: 27px;
    height: 27px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}