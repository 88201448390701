.container {
    margin-top: 10%;
}

.upload-file-container {
    border: 1px solid gray;
    min-height: 350px;
    margin: 0 20px;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-details-container {
    border: 1px solid gray;
    margin: 0 20px;
    height: auto;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.file-upload .react-pdf__Page__canvas {
    width: 100%!important;
    height: 100%!important;
}