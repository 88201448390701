.template-container {
  margin: 10px 20px;
}
/*# sourceMappingURL=Templates.css.map */
.border-left-primary {
    border-left: 0.25rem solid #4e73df!important;
}

.icon-size-user{
  font-size: 18px;
}

