.pdf-container {
    // display: flex;
    // height: 100vh;
    // overflow: hidden; /* Hide scrollbars */
}

.set-up-pdf .react-pdf__Page__canvas {
    // height: 98.5vh!important;
    // width: auto!important;
    // overflow: auto!important;
}

.set-up-pdf .react-pdf__Page__textContent {
    width: 0%!important;
}

.canvas-size {
    overflow: initial!important;
}

.set-up-pdf {

}