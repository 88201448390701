.side-pdf-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

.side-pdf-list-item {
  border: 2px solid grey;
  margin: 16px auto;
  /* width: -webkit-fit-content;
  width: -moz-fit-content; */
  width: 70%;
  height: 180px;
}

/* .view{

} */

.side-pdf-list .react-pdf__Page__canvas {
  width: 100% !important;
  height: 138px !important;
}

.text-content .react-pdf__Page__textContent {
  width: 0% !important;
}

.page-no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}


.accordion-item h5.accordion-header button.accordion-button {
  width: 100%;
  text-align: left;
  border: 1px solid #00000020;
  padding: 10px;
  color: #2f2e2eed;
  background-color: #efefefeb;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-radius: 4px;
  /* background: url(
   ../../assets/images/211687_down_arrow_icon.svg
  ) no-repeat  98% 12px ;
   background-size: 20px; */
  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}
.accordion-item h5.accordion-header button.accordion-button:focus {
  outline: none;
  box-shadow: none;
  transition: transform 0.2s ease-in-out;
  /* background: url(
   ../../assets/images/211687_down_arrow_icon.svg
  ) no-repeat 98% 12px ;
   background-size: 20px; */
}
