@import url('https://cdn.datatables.net/1.11.1/css/jquery.dataTables.min.css');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

/* @import url('https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.1.2/css/fontawesome.min.css'); */



.template-container {
  margin: 10px 20px;
  font-family: 'Open Sans', sans-serif;
}


.shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.dataTables_length ,.dataTables_filter {
  color: #000 ;
  margin-bottom: 20px;

}

 .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px;
  margin: 0 5px;
}

.dataTables_filter {text-align: right;}

 .dataTables_filter input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  margin-left: 3px;
}


.cusTable thead tr th {
  border: 0;
  border-bottom: 2px solid #e3e6f0 !important;
}


table.dataTable thead th, table.dataTable tbody td {
  padding: 12px 10px;
  color: #272727;
}

.cusTable tbody tr td {
  border: 0;
  border-bottom: 1px solid #e3e6f0 !important;
}

.cusTable{border: 0 !important;}

.cusTable tr:nth-child(odd){ background-color: #fff;}

.cusTable tr:nth-child(even){ background-color: #f7f7f7;}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #4e73df !important;
  color: #fff !important;
}
.dataTables_wrapper .dataTables_info , .dataTables_wrapper .dataTables_paginate{margin-top: 20px;}
.ntBtn{background-color: #4e73df  ;}

.actionCont .dropdown-toggle::after{display: none;}
.actionCont a{ text-decoration: none; color: #000;}


.actionCont .dropdown-item {
  font-size: 14px;
}

.add-tmplte-btn{
  color: #fff !important;
}


/*spinner*/
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: url(../../assets/images/popbg.png);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
/*spinner*/